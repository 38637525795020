
import img1 from '../images/icon/icon1.png'
import img2 from '../images/icon/icon2.png'
import img3 from '../images/icon/icon3.png'
import img4 from '../images/icon/icon4.png'


const dataBox = [

    {
        id: 1,
        img: img1,
        title: 'content_1_title',
        desc: 'content_1_description'
    },
    {
        id: 2,
        img: img2,
        title: 'content_2_title',
        desc: 'content_2_description'
    },
    {
        id: 3,
        img: img3,
        title: 'content_3_title',
        desc: 'content_3_description'
    },
    {
        id: 4,
        img: img4,
        title: 'content_4_title',
        desc: 'content_4_description'
    },

]

export default dataBox;