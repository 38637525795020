import React from 'react';
import {useTranslation} from "react-i18next";



function Speciality(props) {
    const { t } = useTranslation()
    const {data} = props;
    return (
        <section className="speciality" id="manageable_website">
                <div className="shape right"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="block-text center">
                                <h6 className="sub-heading"><span>
                                    {t("manageable_website.heading")}
                                </span></h6>
                                <h3 className="heading pd">
                                    {t("manageable_website.sub_heading")}
                                </h3>
                            </div>
                        </div>
                        {
                            data.map(idx => (
                                <div key={idx.id} className="col-xl-3 col-md-6">
                                    <div className="speciality-box" >
                                        <div className="icon">
                                            <img src={idx.img} alt="Cyfonii" />
                                        </div>
                                        <h5 className="title">{t(`manageable_website.${idx.title}`)}</h5>
                                        <p>{t(`manageable_website.${idx.desc}`)}</p>
                                        <h3 className="number">0{idx.id}</h3>
                                    </div>
                                </div>
                            ))
                        }
                        
                    </div>
                </div>
            </section>

    );
}

export default Speciality;