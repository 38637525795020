import React from 'react';
import PropTypes from 'prop-types';

import img from '../../assets/images/background/line.png'
import {useTranslation} from "react-i18next";

Roadmap.propTypes = {
    data: PropTypes.array
};

function Roadmap(props) {
    const { t } = useTranslation()
    const {data} = props;

    return (
        <section className="roadmap" id="reservation_system">
                <img src={img} alt="" className="img-line" />
                <div className="shape"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="block-text center">
                                <h6 className="sub-heading"><span>
                                    {t('reservation_system.heading')}
                                </span></h6>
                                <h3 className="heading pd">
                                    {t('reservation_system.sub_heading')}
                                </h3>
                            </div>

                            <div className="roadmap__main" data-aos="fade-up" data-aos-duration="2000" data-aos-once="true">
                                {
                                    data.map(idx => (
                                        <div key={idx.id} className={`roadmap-box ${idx.class}`} >

                                            <div className="content">
                                                <h5 className="title">{t(`reservation_system.${idx.title}`)}</h5>
                                                <p className="text">{t(`reservation_system.${idx.desc}`)}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                               
                                <div className="icon"></div>

                                
                            </div>
                        </div>

                    </div>
                </div>
            </section>

    );
}

export default Roadmap;