
import img1 from '../images/svg/icon-1.svg'
import img2 from '../images/svg/icon-2.svg'
import img3 from '../images/svg/icon-3.svg'
import img4 from '../images/svg/icon-4.svg'



const dataCommentAnalytic = [

    {
        id: 1,
        img: img1,
        title: 'content_1_title',
        desc: 'content_1_description'
    },
    {
        id: 2,
        img: img2,
        title: 'content_2_title',
        desc: 'content_2_description'
    },
    {
        id: 3,
        img: img3,
        title: 'content_3_title',
        desc: 'content_3_description'
    },
    {
        id: 4,
        img: img4,
        title: 'content_4_title',
        desc: 'content_4_description'
    }
]

export default dataCommentAnalytic;