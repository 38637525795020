import React , {useState} from 'react';

import img1 from '../../assets/images/payment.png'
import {useTranslation} from "react-i18next";

function About(props) {
    const { t } = useTranslation()
    const [dataImg] = useState([
        {
            id: 1,
            img : img1,
            class: 'img1'
        }
    ])
    return (
        <section className="about" id="payment">
                <div className="container">
                    <div className="row rev">
                        <div className="col-xl-6 col-md-12">
                            <div className="about__right">
                                <div className="images">
                                    {
                                        dataImg.map(idx => (
                                            <img key={idx.id} className={idx.class} src={idx.img} alt="cyfonii" />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="block-text" >
                                <h6 className="sub-heading"><span>{t("payment.sub_heading")}</span></h6>
                                <h3 className="heading">{t("payment.heading")}</h3>
                                    <p className="mb-17">
                                        {t("payment.content_1")}
                                    </p>
                                    <p className="mb-17">
                                        {t("payment.content_2")}
                                    </p>
                                    <p className="mb-17">
                                        {t("payment.content_3")}
                                    </p>
                                    <p className="mb-17">
                                        {t("payment.content_4")}
                                    </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default About;