const menus = [
    {
        id: 1,
        name: 'navigation.price_analytic',
        element: 'price_analytic',
    },
    {
        id: 2,
        name: 'navigation.comment_analytic',
        element: 'comment_analytic',
    },
    {
        id: 3,
        name: 'navigation.reservation_system',
        element: 'reservation_system',
    },
    {
        id: 4,
        name: 'navigation.manageable_website',
        element: 'manageable_website',
    },
    {
        id: 5,
        name: 'navigation.payment',
        element: 'payment',
    }
]

export default menus;