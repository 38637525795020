import React , {useState} from 'react';
import PropTypes from 'prop-types';

import img from '../../assets/images/comment_analytic.png'
import {useTranslation} from "react-i18next";

CommentAnalytic.propTypes = {
    data: PropTypes.array
};

function CommentAnalytic(props) {
    const { t } = useTranslation()
    const {data} = props;

    return (
        <section className="portfolio" id="comment_analytic">
                <div className="shape"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="block-text center">
                                <h3 className="heading pd">{t("comment_analytic.heading")}</h3>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6">
                            <div className="portfolio__left">
                                {
                                    data.map(idx => (
                                        <div key={idx.id} className="portfolio-box">
                                            <div className="icon">
                                                <img src={idx.img} alt="Cyfonii" />
                                                    
                                            </div>
                                            <div className="content">
                                                <h5 className="title">{t(`comment_analytic.${idx.title}`)}</h5>
                                                <p>{t(`comment_analytic.${idx.desc}`)}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>



                        <div className="col-xl-6 col-md-6">
                            <div className="portfolio__right">
                                <div className="image" data-aos="fade-left" data-aos-duration="2000" data-aos-once="true">
                                    <img src={img} alt="cyfonii" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default CommentAnalytic;