import React from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../assets/images/banner.png'
import {useTranslation} from "react-i18next";


function Banner(props) {
    const { t } = useTranslation();
    return (
        <section className="banner">
                <div className="shape right"></div>
                <div className="container big">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="banner__left">
                                <div className="block-text">
                                    <h2 className="heading">{t("banner.title")}</h2>
                                    <p className="desc">{t("banner.subtitle")}</p>
                                    <Link to="mailto:info@travelpratic.com" className="action-btn"><span>
                                        {t("navigation.contact_us")}
                                    </span></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">

                            <div className="banner__right">
                                <div className="image">
                                    <img src={img1} alt="cyfonii" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default Banner;