import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const locales = ["tr", "en"];
i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    ...require("./locales/en/general.json")
                }
            },
            tr: {
                translation: {
                    ...require("./locales/tr/general.json")
                }
            }
        },
        lng: locales.includes(localStorage.getItem("locale")) ? localStorage.getItem("locale"): "tr",
        fallbackLng: locales,
        interpolation: {
            escapeValue: false
        }
});