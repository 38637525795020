import React , { useState , useEffect } from 'react';
import { Link , NavLink } from 'react-router-dom';
import menus from '../../pages/menu';
import logo from '../../assets/images/logo.png'
import './styles.scss';
import {useTranslation} from "react-i18next";

const Header = () => {
    const { t, i18n } = useTranslation()
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 300);
        });
        return () => {
            setScroll({});
        }
    }, []);

    const [menuActive, setMenuActive] = useState(null);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
    };

    const [activeIndex, setActiveIndex] = useState(null);
    const handleDropdown = index => {
        setActiveIndex(index); 
    };

    const scrollToElement = (e, element) => {
        e.preventDefault();
        const el = document.getElementById(element);
        if (!el) return;
        el.scrollIntoView({behavior: "smooth"});
        setMenuActive(false);
    }

    const scrollToTop = (e) => {
        e.preventDefault();
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    const handleChangeLanguage = () => {
        if(i18n.language === "tr") {
            i18n.changeLanguage("en");
            localStorage.setItem('locale', 'en');
        } else {
            i18n.changeLanguage("tr");
            localStorage.setItem('locale', 'tr');
        }
    }

    return (
        <header id="header_main" className={`header ${scroll ? 'is-fixed' : ''}`}>
            <div className="container big">
                <div className="row">
                    <div className="col-12">
                        <div className="header__body">
                            <div className="header__logo">
                                <Link to="/" onClick={(e) => scrollToTop(e)}>
                                    <img id="site-logo" src={logo} alt='cyfonii' style={{
                                        width: '220px',
                                        height: 'auto'
                                    }}/>
                                </Link>
                            </div>

                            <div className="header__right">
                            <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`}>
                                    <ul id="menu-primary-menu" className="menu">
                                        {
                                            menus.map((data,idx) => (
                                                <li key={idx} onClick={()=> handleDropdown(idx)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`}>
                                                    <a href={`#${data.element}`} onClick={(e) => scrollToElement(e, data.element)} style={{ whiteSpace: "nowrap", fontSize: "14px" }}>{t(data.name)}</a>
                                                    {
                                                        data.namesub &&
                                                        <ul className="sub-menu">
                                                            {
                                                                data.namesub.map((submenu) => (
                                                                    <li key={submenu.id} className="menu-item"><NavLink to={submenu.links}>{submenu.sub}</NavLink></li>
                                                                ))
                                                            }
                                                        </ul>
                                                    }

                                                </li>
                                            ))
                                        }
                                    </ul>
                                </nav>
                                <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}><span></span></div>
                            </div>

                            <div className="header__action">
                                <button className="action-btn" onClick={() => handleChangeLanguage()}>
                                    <span>{i18n.language === "tr" ? "EN": "TR" }</span>
                                </button>
                                <Link to="mailto:info@travelpratic.com" className="action-btn" style={{ marginLeft: "6px" }}>
                                    <span style={{ fontSize: "12px", whiteSpace: "nowrap" }}>{t("navigation.contact_us")}</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;