

const dataRoadMap = [

    {
        id: 1,
        time: 'February 01, 2022',
        title: 'content_1_title',
        desc: 'content_1_description',
        class: ''
    },
    {
        id: 2,
        time: 'February 01, 2022',
        title: 'content_2_title',
        desc: 'content_2_description',
        class: 'right'
    },

    {
        id: 3,
        time: 'February 01, 2022',
        title: 'content_3_title',
        desc: 'content_3_description',
        class: ''
    },

    {
        id: 4,
        time: 'February 01, 2022',
        title: 'content_4_title',
        desc: 'content_4_description',
        class: 'right'
    }



]

export default dataRoadMap;